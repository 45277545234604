import { useContext } from 'react';
import { LogoContainer, LogoImg } from './styles';
import DarkLogo from 'src/assets/logo_dark.png';
import LightLogo from 'src/assets/logo_light.png';
import { ThemeContext } from 'src/contexts/ThemeContext/ThemeContext';

const Logo = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <LogoContainer>
      <LogoImg src={theme === 'dark' ? DarkLogo : LightLogo} alt="logo" />
    </LogoContainer>
  );
};

export default Logo;
