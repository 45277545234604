import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/users/users';
import { globalActions } from 'src/reducers/global/global';
import {
  getTeam as getTeamApi,
  createUser as createUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  changePassword as changePasswordApi,
} from 'src/services/users/users';
import { User, UserPassword } from 'src/services/users/types';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';

function* getTeam(): Generator<
  CallEffect<AxiosResponse<User[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<User[]>
> {
  try {
    const { status, data } = yield call(getTeamApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getTeamSuccess(data));
    } else {
      yield put(actions.getTeamError());
    }
  } catch (e) {
    yield put(actions.getTeamError());
  }
}

function* createUser({
  payload,
}: PayloadAction<User>): Generator<
  CallEffect<AxiosResponse<User>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<User>
> {
  try {
    const { status, data } = yield call(createUserApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createUserSuccess(data));
      yield put(actions.getTeamRequest());
      yield put(globalActions.showSuccessSnackbar(i18next.t('usersSaga:createSuccessfully')));
    } else {
      yield put(actions.createUserError());
      yield errorController(i18next.t('usersSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createUserError());
    yield errorController(i18next.t('usersSaga:createError'), e);
  }
}

function* updateUser({
  payload,
}: PayloadAction<User>): Generator<
  CallEffect<AxiosResponse<User>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<User>
> {
  try {
    const { status, data } = yield call(updateUserApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateUserSuccess(data));
      yield put(actions.getTeamRequest());
      yield put(globalActions.showSuccessSnackbar(i18next.t('usersSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateUserError());
      yield errorController(i18next.t('usersSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateUserError());
    yield errorController(i18next.t('usersSaga:updateError'), e);
  }
}

function* deleteUser({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteUserApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteUserSuccess());
      yield put(actions.getTeamRequest());
      yield put(globalActions.showSuccessSnackbar(i18next.t('usersSaga:deleteSuccessfully')));
    } else {
      yield put(actions.deleteUserError());
      yield errorController(i18next.t('usersSaga:deleteError'), data);
    }
  } catch (e) {
    yield put(actions.deleteUserError());
    yield errorController(i18next.t('usersSaga:deleteError'), e);
  }
}

function* changePassword({
  payload,
}: PayloadAction<UserPassword>): Generator<
  CallEffect<AxiosResponse<User>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<User>
> {
  try {
    const { status } = yield call(changePasswordApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.changePasswordSuccess());
      yield put(globalActions.toggleChangePasswordModal(false));
    } else {
      yield put(actions.changePasswordError());
    }
  } catch (e) {
    yield put(actions.changePasswordError());
  }
}

const usersSaga: ForkEffect<never>[] = [
  takeLatest(actions.getTeamRequest, getTeam),
  takeLatest(actions.createUserRequest, createUser),
  takeLatest(actions.updateUserRequest, updateUser),
  takeLatest(actions.deleteUserRequest, deleteUser),
  takeLatest(actions.changePasswordRequest, changePassword),
];

export default usersSaga;
