import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ConfirmDialogProps } from './types';
import { useTranslation } from 'react-i18next';
import './i18n';

const ConfirmDialog = ({
  open,
  title,
  message,
  confirmLabel,
  cancelLabel,
  onCancel,
  onConfirm,
}: ConfirmDialogProps) => {
  const { t } = useTranslation('confirm');
  return (
    <Dialog open={open} keepMounted aria-describedby="confirm-dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelLabel || t('cancel')}</Button>
        <Button onClick={onConfirm}>{confirmLabel || t('confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
