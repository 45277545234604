import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'systemDate', {
  title: 'Cambiar fecha del sistema',
  date: 'Fecha',
  placeholder: 'Ingresá la fecha',
  change: 'Cambiar',
  cancel: 'Cancelar',
});

export default i18n;
