import { Cardio } from 'src/services/cardios/types';
import { CalendarTask, TaskType } from 'src/services/tasks/types';
import { Workout } from 'src/services/workouts/types';

export type ClientTasksStoreType = {
  tasks: CalendarTask[];
  canEditFrom: string;
  currentDate: string;
  taskCreator: TaskCreator;
  startDate: string;
  endDate: string;
  selectedTask: SelectedTask | null;
  showDeleteTaskAlert: boolean;
  cardios: Cardio[];
  workouts: Workout[];
};

export type TaskCreator = {
  tasksFilter: string;
  isOpen: boolean;
  clientTaskId?: number;
  date?: string;
  taskType?: TaskType;
  taskId?: number;
  goal?: string;
  duration?: string;
  actualStep: TaskCreatorStep;
};

export type SelectedTask = {
  taskType: TaskType;
  taskId: number;
  clientTaskId?: number;
  date?: string;
  goal?: string;
  duration?: number;
  durationMinutesRegistered?: number;
  burnedCalories?: number;
  distance?: number;
  canEdit: boolean;
  canDelete: boolean;
};

export enum TaskCreatorStep {
  DateSelector = 0,
  TaskSelector,
  AdditionalData,
}

export type ChangeParams = {
  key: keyof TaskCreator;
  value: string | number | boolean | TaskType;
};
