import { get, post } from '../api';
import { Client, VerificationCodeResponse } from './types';

export const getClient = (id: number) => {
  return get<Client>(`/api/v1/clients/${id}`);
};

export const getClients = () => {
  return get<Client[]>('/api/v1/clients');
};

export const createVerificationCode = () => {
  return post<VerificationCodeResponse>('/api/v1/registration_codes', {});
};
