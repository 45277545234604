import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/clients/clients';
import {
  getClients as getClientsApi,
  getClient as getClientApi,
  createVerificationCode,
} from 'src/services/clients/clients';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';
import { Client } from 'src/services/clients/types';
import { PayloadAction } from '@reduxjs/toolkit';

function* getClient({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<Client>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Client>
> {
  try {
    const { status, data } = yield call(getClientApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientSuccess(data));
    } else {
      yield put(actions.getClientError());
    }
  } catch (e) {
    yield put(actions.getClientError());
  }
}

function* getClients(): Generator<
  CallEffect<AxiosResponse<Client[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Client[]>
> {
  try {
    const { status, data } = yield call(getClientsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientsSuccess(data));
    } else {
      yield put(actions.getClientsError());
    }
  } catch (e) {
    yield put(actions.getClientsError());
  }
}

function* generateCode(): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(createVerificationCode);
    if (status >= 200 && status < 300) {
      yield put(actions.generateCodeSuccess(data.code.toString()));
    } else {
      yield put(actions.generateCodeError());
      yield errorController(i18next.t('clientsSaga:generateCodeError'), data);
    }
  } catch (e) {
    yield put(actions.generateCodeError());
    yield errorController(i18next.t('clientsSaga:generateCodeError'), e);
  }
}

const clientsSaga: ForkEffect<never>[] = [
  takeLatest(actions.getClientRequest, getClient),
  takeLatest(actions.getClientsRequest, getClients),
  takeLatest(actions.generateCodeRequest, generateCode),
];

export default clientsSaga;
