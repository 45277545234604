import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ClientsStoreType } from './types';
import { Client } from 'src/services/clients/types';

const initialState: ClientsStoreType = {
  clients: [],
  currentClient: null,
  clientsFilter: {
    filtered: [],
    search: '',
  },
  verificationCode: null,
  showCodeGeneratorModal: false,
};

const sliceName = 'clients';

const clientsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getClientRequest: (state: ClientsStoreType, action: PayloadAction<number>) => {},
    getClientSuccess: (state: ClientsStoreType, action: PayloadAction<Client>) => {
      state.currentClient = action.payload;
    },
    getClientError: (state: ClientsStoreType) => {
      state.currentClient = null;
    },
    getClientsRequest: (state: ClientsStoreType) => {},
    getClientsSuccess: (state: ClientsStoreType, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
      state.clientsFilter.search = '';
      state.clientsFilter.filtered = action.payload;
    },
    getClientsError: (state: ClientsStoreType) => {
      state.clients = [];
      state.clientsFilter.search = '';
      state.clientsFilter.filtered = [];
    },
    generateCodeRequest: (state: ClientsStoreType) => {},
    generateCodeSuccess: (state: ClientsStoreType, action: PayloadAction<string>) => {
      state.verificationCode = action.payload;
      state.showCodeGeneratorModal = true;
    },
    generateCodeError: (state: ClientsStoreType) => {
      state.verificationCode = null;
      state.showCodeGeneratorModal = false;
    },
    toggleVerificationCodeModal: (state: ClientsStoreType, action: PayloadAction<boolean>) => {
      state.showCodeGeneratorModal = action.payload;
    },
    setCurrentClient: (state: ClientsStoreType, action: PayloadAction<Client | null>) => {
      state.currentClient = action.payload;
    },
    filterClients: (state: ClientsStoreType, action: PayloadAction<string>) => {
      const { payload } = action;
      const text = payload.toLowerCase();
      state.clientsFilter.search = payload;
      state.clientsFilter.filtered = state.clients.filter(
        c =>
          c.email.toLowerCase().includes(text) ||
          c.firstName.toLowerCase().includes(text) ||
          c.lastName.toLowerCase().includes(text),
      );
    },
  },
});

export const actions = clientsSlice.actions;

export const clientsStore = (state: RootState) => state.clients;

export default clientsSlice.reducer;
