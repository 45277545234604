import { User, UserPassword } from './types';
import { get, remove, post, put } from '../api';

export const getTeam = () => {
  return get<User[]>('/api/v1/coaches');
};

export const createUser = (user: User) => {
  return post<User>('/api/v1/users', user);
};

export const updateUser = (user: User) => {
  const { id, ...params } = user;
  return put<User>(`/api/v1/users/${id}`, params);
};

export const deleteUser = (userId: number) => {
  return remove(`/api/v1/users/${userId}`);
};

export const changePassword = (body: UserPassword) => {
  const { id, ...params } = body;
  return put<User>(`/api/v1/users/${id}/password`, params);
};