import styled, { css } from 'styled-components';

export const AppContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  overflow: hidden;

  ${({ theme }) => css`
    --fc-border-color: ${theme.palette.divider};
    --fc-today-bg-color: ${theme.palette.background.paper};
    --fc-button-bg-color: ${theme.palette.primary.main};
    --fc-button-hover-bg-color: ${theme.palette.primary.dark};
    --fc-button-active-bg-color: ${theme.palette.primary.dark};
    --fc-button-hover-border-color: ${theme.palette.background.default};
    --fc-button-border-color: ${theme.palette.background.default};
  `};

  .fc .fc-button:focus,
  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc-today-button {
    display: none;
  }

  .fc .fc-daygrid-event-harness {
    margin-top: 2px !important;
  }

  .fc-h-event {
    border: none;
    background-color: transparent;
  }

  .fc-daygrid-event {
    white-space: normal;
  }
`;
