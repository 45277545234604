/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeOptions } from '@mui/material';

const primary = '#55E590';
const primaryLight = '#232323';

const dark = {
  main: '#232323',
  darker: '#141414',
  border: '#32383e',
  text: '#fff',
};

const light = {
  main: '#f5f5f5',
  lighter: '#fff',
  border: '#e5e5e5',
  text: '#495057',
  textDark: '#141414',
};

export const lightThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: light.text,
    },
    subtitle2: {
      fontSize: 13,
    },
  },
  tasks: {
    cardio: {
      color: '#ffc9ed',
      contrast: dark.main,
    },
    workout: {
      color: '#b5d2ff',
      contrast: dark.main,
    },
    nutrition: {
      color: '#ffe9c9',
      contrast: dark.main,
    },
  },
  palette: {
    primary: {
      main: primaryLight,
    },
    secondary: {
      main: dark.main,
    },
    background: {
      default: light.main,
      paper: light.lighter,
    },
    divider: light.border,
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderTop: `1px solid ${light.border}`,
          '& > tr': {
            backgroundColor: light.lighter,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: light.main,
          height: '72px',
          '&:last-child > td': {
            border: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
          fontSize: 15,
          padding: '12px 16px',
          borderBottomColor: light.border,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: light.main,
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlinedPrimary: {
          backgroundColor: `${primaryLight}24`,
          border: 'none',
          textTransform: 'none',
          transition: 'all 0.3s ease-in-out',
          padding: '6px 16px',
          '&:hover': {
            backgroundColor: primaryLight,
            color: dark.text,
            border: 'none',
          },
          '&:disabled': {
            border: 'none',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& > div': {
            backgroundColor: light.lighter,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'linear-gradient(rgba(255,255,255,0.1),rgba(255,255,255,0.05))',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: '#f8f8f8',
          },
        },
      },
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  ...lightThemeOptions,
  typography: {
    ...lightThemeOptions.typography,
    allVariants: {
      color: dark.text,
    },
  },
  palette: {
    ...lightThemeOptions.palette,
    mode: 'dark',
    primary: {
      main: primary,
    },
    secondary: {
      main: light.main,
    },
    background: {
      default: dark.main,
      paper: dark.darker,
    },
    divider: dark.border,
  },
  components: {
    ...lightThemeOptions.components,
    MuiTableHead: {
      ...lightThemeOptions.components?.MuiTableHead,
      styleOverrides: {
        ...lightThemeOptions.components?.MuiTableHead?.styleOverrides,
        root: {
          ...(lightThemeOptions.components?.MuiTableHead?.styleOverrides?.root as any),
          borderTopColor: dark.border,
          '& > tr': {
            backgroundColor: '#ffffff10',
          },
        },
      },
    },
    MuiTableRow: {
      ...lightThemeOptions.components?.MuiTableRow,
      styleOverrides: {
        ...lightThemeOptions.components?.MuiTableRow?.styleOverrides,
        root: {
          ...(lightThemeOptions.components?.MuiTableRow?.styleOverrides?.root as any),
          backgroundColor: dark.main,
        },
      },
    },
    MuiTableCell: {
      ...lightThemeOptions.components?.MuiTableCell,
      styleOverrides: {
        ...lightThemeOptions.components?.MuiTableCell?.styleOverrides,
        root: {
          ...(lightThemeOptions.components?.MuiTableCell?.styleOverrides?.root as any),
          borderBottomColor: dark.border,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: dark.main,
          color: dark.text,
          backgroundImage: 'none',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& > div': {
            backgroundColor: dark.main,
          },
        },
      },
    },
    MuiButton: {
      ...(lightThemeOptions.components?.MuiButton as any),
      styleOverrides: {
        ...(lightThemeOptions.components?.MuiButton?.styleOverrides as any),
        outlinedPrimary: {
          ...(lightThemeOptions.components?.MuiButton?.styleOverrides?.outlinedPrimary as any),
          backgroundColor: `${primary}24`,
          '&:hover': {
            backgroundColor: primary,
            color: light.textDark,
            border: 'none',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: '#282B2C',
          },
        },
      },
    },
  },
};
