import { FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalActions, globalStore } from 'src/reducers/global/global';

export const useSystemDate = () => {
  const { systemDate } = useSelector(globalStore);
  const [value, setValue] = useState<Date | string>(systemDate ? new Date(systemDate) : '');
  const dispatch = useDispatch();
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof value !== 'string') {
      const [date] = value.toISOString().split('T');
      if (date) dispatch(globalActions.updateSystemDateRequest(date + ' 00:00:00'));
    }
  };

  useEffect(() => {
    if (systemDate) setValue(new Date(systemDate));
  }, [systemDate]);

  return {
    value,
    setValue,
    handleSubmit,
  };
};
