/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';

import { getValue, removeItem } from '../utils/localStorage';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token: string) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};

api.interceptors.request.use(request => {
  if (request.headers && !request.headers.Authorization) {
    const token = getValue('token');
    request.headers.Authorization = `Bearer ${token}`;
    setAuthToken(token);
  }
  return request;
});

api.interceptors.response.use(
  response => {
    if (response.status === 204) {
      return response;
    }
    // cubre el caso de un download de un archivo
    if (response.status === 200 && response.request.responseType === 'blob') {
      return response;
    }
    return response;
  },
  error => {
    if (!error.status && !error.response && error.message !== 'Network Error') {
      window.location.reload();
    }
    if (error.response) {
      if (error.response.status !== 401) {
        return error.response;
      } else {
        removeItem('token');
        setAuthToken('');
      }
    }

    // se agrega el atributo para saber que es un error de saga
    error.httpError = true;
    throw error;
  },
);

export const remove = <T>(path: string, body?: object) => api.delete<T>(path, { params: body });
export const get = <T>(path: string, query?: object) => api.get<T>(path, { params: query });
export const post = <T>(path: string, body: object, config?: AxiosRequestConfig<any> | undefined) =>
  api.post<T>(path, body, config);
export const put = <T>(path: string, body: object, config?: AxiosRequestConfig<any> | undefined) =>
  api.put<T>(path, body, config);
export const patch = <T>(path: string, body: object, query?: object) => api.patch<T>(path, body, query);
export default api;
