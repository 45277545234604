import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import './i18n';
import { Modal, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormContainer, ButtonsContainer } from './styles';
import { useSystemDate } from './useSystemDate';
import { SystemDateProps } from './types';
import { es } from "date-fns/locale";

const SystemDate = ({ open, onClose }: SystemDateProps) => {
  const { t } = useTranslation('systemDate');
  const { value, setValue, handleSubmit } = useSystemDate();

  return (
    <Modal open={open}>
      <FormContainer onSubmit={handleSubmit}>
        <Typography variant="h6">{t('title')}</Typography>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={es}>
          <DesktopDatePicker
            label={t('date')}
            format="dd/MM/yyyy"
            value={value}
            onChange={value => setValue(value || '')}
          />
        </LocalizationProvider>
        <ButtonsContainer>
          <Button type="button" variant="text" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type="submit" variant="contained" disabled={!value}>
            {t('change')}
          </Button>
        </ButtonsContainer>
      </FormContainer>
    </Modal>
  );
};
export default SystemDate;
