import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest, select, SelectEffect } from 'redux-saga/effects';
import { actions, clientTasksStore } from 'src/reducers/clientTasks/clientTasks';
import {
  getClientTasks as getClientTasksApi,
  deleteClientCardioTask,
  createClientCardioTask,
  updateClientCardioTask,
  deleteClientWorkoutTask,
  createClientWorkoutTask,
  updateClientWorkoutTask,
} from 'src/services/tasks/tasks';
import { getCardios as getCardiosApi } from 'src/services/cardios/cardios';
import { getWorkouts as getWorkoutsApi } from 'src/services/workouts/workouts';
import './i18n';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CalendarTasks,
  ClientTasksRequest,
  CreateClientTaskRequest,
  DeleteClientTaskRequest,
} from 'src/services/tasks/types';
import { errorController } from '../utils/errorController';
import { globalActions } from 'src/reducers/global/global';
import i18next from 'i18next';
import { ClientTasksStoreType } from 'src/reducers/clientTasks/types';
import { Cardio } from 'src/services/cardios/types';
import { Workout } from 'src/services/workouts/types';

function* getClientTasks({
  payload,
}: PayloadAction<ClientTasksRequest>): Generator<
  CallEffect<AxiosResponse<CalendarTasks>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CalendarTasks>
> {
  try {
    const { status, data } = yield call(getClientTasksApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientTasksSuccess(data));
    } else {
      yield put(actions.getClientTasksError());
    }
  } catch (e) {
    yield put(actions.getClientTasksError());
  }
}

function* createCardioTask({
  payload,
}: PayloadAction<CreateClientTaskRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController> | SelectEffect,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(createClientCardioTask, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.closeTaskCreator());
      const store = yield select(clientTasksStore);
      const { startDate, endDate } = store as unknown as ClientTasksStoreType;
      yield put(actions.getClientTasksRequest({ clientId: payload.clientId, startDate, endDate }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientTasksSaga:createSuccessfully')));
    } else {
      yield put(actions.createClientCardioTaskError());
      yield errorController(i18next.t('clientTasksSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createClientCardioTaskError());
    yield errorController(i18next.t('clientTasksSaga:createError'), e);
  }
}

function* updateCardioTask({
  payload,
}: PayloadAction<CreateClientTaskRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController> | SelectEffect,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateClientCardioTask, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.closeTaskCreator());
      const store = yield select(clientTasksStore);
      const { startDate, endDate } = store as unknown as ClientTasksStoreType;
      yield put(actions.getClientTasksRequest({ clientId: payload.clientId, startDate, endDate }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientTasksSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateClientCardioTaskError());
      yield errorController(i18next.t('clientTasksSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateClientCardioTaskError());
    yield errorController(i18next.t('clientTasksSaga:updateError'), e);
  }
}

function* deleteCardioTask({
  payload,
}: PayloadAction<DeleteClientTaskRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController> | SelectEffect,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteClientCardioTask, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteClientCardioTaskSuccess());
      const store = yield select(clientTasksStore);
      const { startDate, endDate } = store as unknown as ClientTasksStoreType;
      yield put(actions.getClientTasksRequest({ clientId: payload.clientId, startDate, endDate }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientTasksSaga:deleteSuccessfully')));
    } else {
      yield put(actions.deleteClientCardioTaskError());
      yield errorController(i18next.t('clientTasksSaga:deleteError'), data);
    }
  } catch (e) {
    yield put(actions.deleteClientCardioTaskError());
    yield errorController(i18next.t('clientTasksSaga:deleteError'), e);
  }
}

function* getCardios(): Generator<
  CallEffect<AxiosResponse<Cardio[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Cardio[]>
> {
  try {
    const { status, data } = yield call(getCardiosApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getCardiosSuccess(data));
    } else {
      yield put(actions.getCardiosError());
    }
  } catch (e) {
    yield put(actions.getCardiosError());
  }
}

function* createWorkoutTask({
  payload,
}: PayloadAction<CreateClientTaskRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController> | SelectEffect,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(createClientWorkoutTask, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.closeTaskCreator());
      const store = yield select(clientTasksStore);
      const { startDate, endDate } = store as unknown as ClientTasksStoreType;
      yield put(actions.getClientTasksRequest({ clientId: payload.clientId, startDate, endDate }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientTasksSaga:createSuccessfully')));
    } else {
      yield put(actions.createClientWorkoutTaskError());
      yield errorController(i18next.t('clientTasksSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createClientWorkoutTaskError());
    yield errorController(i18next.t('clientTasksSaga:createError'), e);
  }
}

function* updateWorkoutTask({
  payload,
}: PayloadAction<CreateClientTaskRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController> | SelectEffect,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateClientWorkoutTask, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.closeTaskCreator());
      const store = yield select(clientTasksStore);
      const { startDate, endDate } = store as unknown as ClientTasksStoreType;
      yield put(actions.getClientTasksRequest({ clientId: payload.clientId, startDate, endDate }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientTasksSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateClientWorkoutTaskError());
      yield errorController(i18next.t('clientTasksSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateClientWorkoutTaskError());
    yield errorController(i18next.t('clientTasksSaga:updateError'), e);
  }
}

function* deleteWorkoutTask({
  payload,
}: PayloadAction<DeleteClientTaskRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController> | SelectEffect,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteClientWorkoutTask, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteClientWorkoutTaskSuccess());
      const store = yield select(clientTasksStore);
      const { startDate, endDate } = store as unknown as ClientTasksStoreType;
      yield put(actions.getClientTasksRequest({ clientId: payload.clientId, startDate, endDate }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientTasksSaga:deleteSuccessfully')));
    } else {
      yield put(actions.deleteClientWorkoutTaskError());
      yield errorController(i18next.t('clientTasksSaga:deleteError'), data);
    }
  } catch (e) {
    yield put(actions.deleteClientWorkoutTaskError());
    yield errorController(i18next.t('clientTasksSaga:deleteError'), e);
  }
}

function* getWorkouts(): Generator<
  CallEffect<AxiosResponse<Workout[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Workout[]>
> {
  try {
    const { status, data } = yield call(getWorkoutsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getWorkoutsSuccess(data));
    } else {
      yield put(actions.getWorkoutsError());
    }
  } catch (e) {
    yield put(actions.getWorkoutsError());
  }
}

const clientTasksSaga: ForkEffect<never>[] = [
  takeLatest(actions.getClientTasksRequest, getClientTasks),
  takeLatest(actions.createClientCardioTaskRequest, createCardioTask),
  takeLatest(actions.updateClientCardioTaskRequest, updateCardioTask),
  takeLatest(actions.deleteClientCardioTaskRequest, deleteCardioTask),
  takeLatest(actions.getCardiosRequest, getCardios),
  takeLatest(actions.createClientWorkoutTaskRequest, createWorkoutTask),
  takeLatest(actions.updateClientWorkoutTaskRequest, updateWorkoutTask),
  takeLatest(actions.deleteClientWorkoutTaskRequest, deleteWorkoutTask),
  takeLatest(actions.getWorkoutsRequest, getWorkouts),
];

export default clientTasksSaga;
