import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/exercises/exercises';
import { getExercises as getExercisesApi } from 'src/services/exercises/exercises';
import { Exercise } from 'src/services/exercises/types';

function* getExercises(): Generator<
  CallEffect<AxiosResponse<Exercise[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Exercise[]>
> {
  try {
    const { status, data } = yield call(getExercisesApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getExercisesSuccess(data));
    } else {
      yield put(actions.getExercisesError());
    }
  } catch (e) {
    yield put(actions.getExercisesError());
  }
}

const exercisesSaga: ForkEffect<never>[] = [takeLatest(actions.getExercisesRequest, getExercises)];

export default exercisesSaga;
