import { Drawer, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import MENU from './menu';
import { MenuItemType, NavbarProps } from './types';
import './i18n';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStore } from 'src/reducers/auth/auth';
import Logo from '../Logo/Logo';

const Navbar = ({ open, onClose }: NavbarProps) => {
  const { t } = useTranslation('menu');
  const { user } = useSelector(authStore);
  const navigate = useNavigate();

  const redirecTo = (item: MenuItemType) => () => {
    const { path, beforeRedirect } = item;
    const route = beforeRedirect ? beforeRedirect(path) : path;
    navigate(route);
  };

  const renderItems = () => {
    return MENU.filter(item => user && item.roles.includes(user?.role)).map(item => (
      <ListItem key={item.key} disablePadding>
        <ListItemButton onClick={redirecTo(item)}>
          <ListItemIcon>
            <Icon>{item.icon}</Icon>
          </ListItemIcon>
          <ListItemText primary={t(`${item.key}`)} />
        </ListItemButton>
      </ListItem>
    ));
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box sx={{ width: { xs: 250, xl: 300 } }} role="presentation" onClick={onClose} onKeyDown={onClose}>
        <Logo />
        <List>{renderItems()}</List>
      </Box>
    </Drawer>
  );
};

export default Navbar;
