import { useState } from 'react';
import { Navbar, Header, ConfirmDialog } from 'src/components/';
import { PageContainer } from './styles';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';
import { globalStore } from 'src/reducers/global/global';

const PrivateLayout = () => {
  const [openNavbar, setOpenNavbar] = useState(false);
  const { user } = useSelector(authStore);
  const { alert } = useSelector(globalStore);

  const handleNavbar = (open: boolean) => () => setOpenNavbar(open);

  if (!user) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return (
    <>
      <Header onMenuClicked={handleNavbar(true)} />
      <Navbar open={openNavbar} onClose={handleNavbar(false)} />
      <PageContainer>
        <div>
          <Outlet />
        </div>
      </PageContainer>
      <ConfirmDialog
        open={alert.show}
        title={alert.title}
        message={alert.message}
        cancelLabel={alert.cancelLabel}
        confirmLabel={alert.confirmLabel}
        onCancel={alert.onCancel}
        onConfirm={alert.onConfirm}
      />
    </>
  );
};

export default PrivateLayout;
