import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'menu', {
  team: 'Equipo',
  clients: 'Clientes',
  cardios: 'Cardios',
  exercises: 'Ejercicios',
  workouts: 'Workouts',
});

export default i18n;
