import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';

const checkPassword = (value: string | undefined, context: yup.TestContext<AnyObject>) => {
  const { parent, createError } = context;
  const form = parent as ChangePasswordFormType;
  if (form.newPassword !== value) {
    return createError({
      path: 'newPassword2',
      message: 'passwordMustMatch',
    });
  }

  return true;
};

export const validationSchema = yup.object({
  currentPassword: yup.string().required('Requerido'),
  newPassword: yup.string().min(8, 'passwordMinLength').required('Requerido'),
  newPassword2: yup.string().min(8, 'passwordMinLength').required('Requerido').test('check-password', checkPassword),
});

export type ChangePasswordFormType = yup.InferType<typeof validationSchema>;
