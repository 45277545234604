import { get, post, put, remove } from '../api';
import { CalendarTasks, ClientTasksRequest, DeleteClientTaskRequest, CreateClientTaskRequest } from './types';

export const getClientTasks = (params: ClientTasksRequest) => {
  const { clientId, startDate, endDate } = params;
  return get<CalendarTasks>(`/api/v1/clients/${clientId}/tasks`, { start_date: startDate, end_date: endDate });
};

export const deleteClientCardioTask = (params: DeleteClientTaskRequest) => {
  const { clientId, taskId } = params;
  return remove(`/api/v1/clients/${clientId}/cardio-tasks/${taskId}`);
};

export const createClientCardioTask = (params: CreateClientTaskRequest) => {
  const { clientId, ...body } = params;
  return post(`/api/v1/clients/${clientId}/cardio-tasks`, body);
};

export const updateClientCardioTask = (params: CreateClientTaskRequest) => {
  const { clientId, clientTaskId, ...body } = params;
  return put(`/api/v1/clients/${clientId}/cardio-tasks/${clientTaskId}`, body);
};

export const deleteClientWorkoutTask = (params: DeleteClientTaskRequest) => {
  const { clientId, taskId } = params;
  return remove(`/api/v1/clients/${clientId}/workout-tasks/${taskId}`);
};

export const createClientWorkoutTask = (params: CreateClientTaskRequest) => {
  const { clientId, ...body } = params;
  return post(`/api/v1/clients/${clientId}/workout-tasks`, body);
};

export const updateClientWorkoutTask = (params: CreateClientTaskRequest) => {
  const { clientId, clientTaskId, ...body } = params;
  return put(`/api/v1/clients/${clientId}/workout-tasks/${clientTaskId}`, body);
};
