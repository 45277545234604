import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { globalActions } from 'src/reducers/global/global';
import { updateSystemDate as updateSystemDataApi, getSystemDate as getSystemDateApi } from 'src/services/global/global';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';

function* getSystemDate(): Generator<
  CallEffect<AxiosResponse<{ datetime: string }>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<{ datetime: string }>
> {
  try {
    const { status, data } = yield call(getSystemDateApi);
    if (status >= 200 && status < 300) {
      yield put(globalActions.getSystemDateSuccess(data.datetime));
    } else {
      yield put(globalActions.getSystemDateError());
    }
  } catch (e) {
    yield put(globalActions.getSystemDateError());
  }
}

function* updateSystemDate({
  payload,
}: PayloadAction<string>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateSystemDataApi, payload);
    if (status >= 200 && status < 300) {
      yield put(globalActions.updateSystemDateSuccess(payload));
      yield put(globalActions.showSuccessSnackbar(i18next.t('globalSaga:updateSystemDateSuccessfully')));
    } else {
      yield put(globalActions.updateSystemDateError());
      yield errorController(i18next.t('globalSaga:updateSystemDateError'), data);
    }
  } catch (e) {
    yield put(globalActions.updateSystemDateError());
    yield errorController(i18next.t('globalSaga:updateSystemDateError'), e);
  }
}

const globalSaga: ForkEffect<never>[] = [
  takeLatest(globalActions.updateSystemDateRequest, updateSystemDate),
  takeLatest(globalActions.getSystemDateRequest, getSystemDate),
];

export default globalSaga;
