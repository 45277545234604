import styled from 'styled-components';

export const FormContainer = styled.form`
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.background.default};
  width: 340px;
  max-width: 90%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`;
