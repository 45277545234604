import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ChangeParams, ClientTasksStoreType, SelectedTask, TaskCreator, TaskCreatorStep } from './types';
import {
  CalendarTasks,
  ClientTasksRequest,
  CreateClientTaskRequest,
  DeleteClientTaskRequest,
} from 'src/services/tasks/types';
import { Cardio } from 'src/services/cardios/types';
import { Workout } from 'src/services/workouts/types';

const initialState: ClientTasksStoreType = {
  tasks: [],
  canEditFrom: '',
  currentDate: '',
  taskCreator: {
    tasksFilter: '',
    isOpen: false,
    actualStep: TaskCreatorStep.DateSelector,
  },
  startDate: '',
  endDate: '',
  selectedTask: null,
  showDeleteTaskAlert: false,
  cardios: [],
  workouts: [],
};

const sliceName = 'clientTasks';

const clientTasksSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getClientTasksRequest: (state: ClientTasksStoreType, action: PayloadAction<ClientTasksRequest>) => {
      const { payload } = action;
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    getClientTasksSuccess: (state: ClientTasksStoreType, action: PayloadAction<CalendarTasks>) => {
      const { canEditFrom, currentDate, dates } = action.payload;
      state.tasks = dates;
      state.canEditFrom = canEditFrom;
      state.currentDate = currentDate;
    },
    getClientTasksError: (state: ClientTasksStoreType) => {
      state.tasks = [];
    },
    deleteClientCardioTaskRequest: (state: ClientTasksStoreType, action: PayloadAction<DeleteClientTaskRequest>) => {},
    deleteClientCardioTaskSuccess: (state: ClientTasksStoreType) => {
      state.showDeleteTaskAlert = false;
      state.selectedTask = null;
    },
    deleteClientCardioTaskError: (state: ClientTasksStoreType) => {
      state.showDeleteTaskAlert = false;
      state.selectedTask = null;
    },
    createClientCardioTaskRequest: (state: ClientTasksStoreType, action: PayloadAction<CreateClientTaskRequest>) => {},
    createClientCardioTaskSuccess: (state: ClientTasksStoreType) => {},
    createClientCardioTaskError: (state: ClientTasksStoreType) => {},
    updateClientCardioTaskRequest: (state: ClientTasksStoreType, action: PayloadAction<CreateClientTaskRequest>) => {},
    updateClientCardioTaskSuccess: (state: ClientTasksStoreType) => {},
    updateClientCardioTaskError: (state: ClientTasksStoreType) => {},
    deleteClientWorkoutTaskRequest: (state: ClientTasksStoreType, action: PayloadAction<DeleteClientTaskRequest>) => {},
    deleteClientWorkoutTaskSuccess: (state: ClientTasksStoreType) => {
      state.showDeleteTaskAlert = false;
      state.selectedTask = null;
    },
    deleteClientWorkoutTaskError: (state: ClientTasksStoreType) => {
      state.showDeleteTaskAlert = false;
      state.selectedTask = null;
    },
    createClientWorkoutTaskRequest: (state: ClientTasksStoreType, action: PayloadAction<CreateClientTaskRequest>) => {},
    createClientWorkoutTaskSuccess: (state: ClientTasksStoreType) => {},
    createClientWorkoutTaskError: (state: ClientTasksStoreType) => {},
    updateClientWorkoutTaskRequest: (state: ClientTasksStoreType, action: PayloadAction<CreateClientTaskRequest>) => {},
    updateClientWorkoutTaskSuccess: (state: ClientTasksStoreType) => {},
    updateClientWorkoutTaskError: (state: ClientTasksStoreType) => {},
    getCardiosRequest: (state: ClientTasksStoreType) => {},
    getCardiosSuccess: (state: ClientTasksStoreType, action: PayloadAction<Cardio[]>) => {
      state.cardios = action.payload;
    },
    getCardiosError: (state: ClientTasksStoreType) => {
      state.cardios = [];
    },
    getWorkoutsRequest: (state: ClientTasksStoreType) => {},
    getWorkoutsSuccess: (state: ClientTasksStoreType, action: PayloadAction<Workout[]>) => {
      state.workouts = action.payload;
    },
    getWorkoutsError: (state: ClientTasksStoreType) => {
      state.workouts = [];
    },
    setSelectedTask: (state: ClientTasksStoreType, action: PayloadAction<SelectedTask | null>) => {
      state.selectedTask = action.payload;
    },
    toggleDeleteTaskAlert: (state: ClientTasksStoreType, action: PayloadAction<boolean>) => {
      state.showDeleteTaskAlert = action.payload;
    },
    openTaskCreator: (state: ClientTasksStoreType, action: PayloadAction<string>) => {
      state.taskCreator.isOpen = true;
      state.taskCreator.date = action.payload;
      state.taskCreator.actualStep = TaskCreatorStep.DateSelector;
    },
    openEditCreator: (state: ClientTasksStoreType, action: PayloadAction<TaskCreator>) => {
      state.taskCreator.isOpen = true;
      state.taskCreator.tasksFilter = '';
      state.taskCreator.actualStep = TaskCreatorStep.DateSelector;
      state.taskCreator = action.payload;
    },
    closeTaskCreator: (state: ClientTasksStoreType) => {
      state.taskCreator.isOpen = false;
      state.taskCreator.date = '';
      state.taskCreator.goal = '';
      state.taskCreator.duration = '';
      state.taskCreator.tasksFilter = '';
      state.taskCreator.clientTaskId = undefined;
      state.taskCreator.taskId = undefined;
      state.taskCreator.taskType = undefined;
      state.taskCreator.actualStep = TaskCreatorStep.DateSelector;
      state.cardios = [];
    },
    setActualStep: (state: ClientTasksStoreType, action: PayloadAction<TaskCreatorStep>) => {
      state.taskCreator.actualStep = action.payload;
    },
    onChange: (state: ClientTasksStoreType, action: PayloadAction<ChangeParams>) => {
      const { key, value } = action.payload;
      (state.taskCreator[key] as typeof value) = value;
    },
    onChangeTasksFilter: (state: ClientTasksStoreType, action: PayloadAction<string>) => {
      state.taskCreator.tasksFilter = action.payload;
    },
  },
});

export const actions = clientTasksSlice.actions;

export const clientTasksStore = (state: RootState) => state.clientTasks;

export default clientTasksSlice.reducer;
