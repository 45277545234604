import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'clientTasksSaga', {
  createSuccessfully: 'La tarea fue creada exitosamente',
  updateSuccessfully: 'La tarea fue actualizada exitosamente',
  deleteSuccessfully: 'La tarea fue eliminada exitosamente',
  createError: 'Ocurrió un error al crear la tarea. Vuelva a intentarlo en unos minutos',
  updateError: 'Ocurrió un error al actualizar la tarea. Vuelva a intentarlo en unos minutos',
  deleteError: 'Ocurrió un error al eliminar la tarea. Vuelva a intentarlo en unos minutos',
});

export default i18n;
