import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertType, GlobalStoreType } from './types';
import { RootState } from '../store';

const initialState: GlobalStoreType = {
  loading: false,
  showChangePasswordModal: false,
  showSystemDateModal: false,
  systemDate: '',
  snackbar: null,
  alert: {
    show: false,
    message: '',
    title: '',
    onCancel: () => {},
    onConfirm: () => {},
  },
};

function isRequestAction(action: AnyAction) {
  return action.type.endsWith('Request');
}

function isResponseAction(action: AnyAction) {
  return action.type.endsWith('Error') || action.type.endsWith('Success');
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleChangePasswordModal: (state: GlobalStoreType, action: PayloadAction<boolean>) => {
      state.showChangePasswordModal = action.payload;
    },
    toggleSystemDateModal: (state: GlobalStoreType, action: PayloadAction<boolean>) => {
      state.showSystemDateModal = action.payload;
    },
    showSuccessSnackbar: (state: GlobalStoreType, action: PayloadAction<string>) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        status: 'success',
      };
    },
    getSystemDateRequest: (state: GlobalStoreType) => {},
    getSystemDateSuccess: (state: GlobalStoreType, action: PayloadAction<string>) => {
      let date = action.payload;
      if(!action.payload.includes('T')) date = action.payload.split(" ").join("T");
      state.systemDate = date;
    },
    getSystemDateError: (state: GlobalStoreType) => {},
    updateSystemDateRequest: (state: GlobalStoreType, action: PayloadAction<string>) => {},
    updateSystemDateSuccess: (state: GlobalStoreType, action: PayloadAction<string>) => {
      let date = action.payload;
      if(!action.payload.includes('T')) date = action.payload.split(" ").join("T");
      state.showSystemDateModal = false;
      state.systemDate = date;
    },
    updateSystemDateError: (state: GlobalStoreType) => {},
    showErrorSnackbar: (state: GlobalStoreType, action: PayloadAction<string>) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        status: 'error',
      };
    },
    closeSnackbar: (state: GlobalStoreType) => {
      state.snackbar = {
        show: false,
        message: '',
        status: 'info',
      };
    },
    setAlert: (state: GlobalStoreType, action: PayloadAction<AlertType>) => {
      state.alert = action.payload;
    },
    closeAlert: (state: GlobalStoreType) => {
      state.alert.show = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isRequestAction, (state: GlobalStoreType) => {
        state.loading = true;
      })
      .addMatcher(isResponseAction, (state: GlobalStoreType) => {
        state.loading = false;
      });
  },
});

export const globalActions = globalSlice.actions;

export const globalStore = (state: RootState) => state.global;

export default globalSlice.reducer;
