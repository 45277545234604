import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { actions } from 'src/reducers/auth/auth';
import { validationSchema, ChangePasswordFormType } from './schema';

export const useChangePassword = () => {
  const dispatch = useDispatch();

  const form = useFormik<ChangePasswordFormType>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPassword2: '',
    },
    initialErrors: {
      currentPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      dispatch(
        actions.changeMyPasswordRequest({
          currentPassword: values.currentPassword,
          password: values.newPassword,
          confirmPassword: values.newPassword2,
        }),
      );
    },
  });

  return {
    form,
  };
};
