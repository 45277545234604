import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangePasswordRequest, LoginRequestType, LoginResponseType } from 'src/services/auth/types';
import { AuthStoreType, JWTPayloadType, RoleEnum, UserType } from './types';
import jwtDecode from 'jwt-decode';
import { removeItem } from 'src/utils/localStorage';
import { RootState } from '../store';

const initialState: AuthStoreType = {
  user: null,
  message: '',
};

const getSessionInformation = (token: string): UserType | null => {
  try {
    const payload = jwtDecode(token) as JWTPayloadType;
    return {
      id: payload.id,
      username: payload.username,
      firstName: payload.first_name,
      lastName: payload.last_name,
      email: payload.email,
      role: payload.role === 'AD' ? RoleEnum.ADMIN : RoleEnum.COACH,
      gymId: payload.gym_id,
    };
  } catch (err) {
    removeItem('token');
    return null;
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state: AuthStoreType, action: PayloadAction<LoginRequestType>) => {},
    loginError: (state: AuthStoreType) => {
      state.message = 'Usuario o contraseña inválida';
    },
    loginSuccess: (state: AuthStoreType, action: PayloadAction<LoginResponseType>) => {
      const { payload } = action;
      state.user = getSessionInformation(payload.access);
    },
    changeMyPasswordRequest: (state: AuthStoreType, action: PayloadAction<ChangePasswordRequest>) => {},
    changeMyPasswordError: (state: AuthStoreType) => {},
    changeMyPasswordSuccess: (state: AuthStoreType) => {},
    setUserInformation: (state: AuthStoreType, action: PayloadAction<string>) => {
      const { payload } = action;
      state.user = getSessionInformation(payload);
    },
    logout: (state: AuthStoreType) => {
      state.message = '';
      state.user = null;
    },
  },
});

export const actions = authSlice.actions;

export const authStore = (state: RootState) => state.auth;

export default authSlice.reducer;
