/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, spawn, put } from 'redux-saga/effects';
import { Effect } from './types';
import { actions } from '../reducers/auth/auth';
import { removeItem } from '../utils/localStorage';
import authSaga from './auth/auth';
import usersSaga from './users/users';
import globalSaga from './global/global';
import clientsSaga from './clients/clients';
import clientTasksSaga from './clientTasks/clientTasks';
import cardiosSaga from './cardios/cardios';
import exercisesSaga from './exercises/exercises';
import workoutsSaga from './workouts/workouts';

const sagas = [
  ...authSaga,
  ...usersSaga,
  ...globalSaga,
  ...clientsSaga,
  ...clientTasksSaga,
  ...cardiosSaga,
  ...exercisesSaga,
  ...workoutsSaga
];

export default function* rootSaga() {
  yield all(
    sagas.map((saga: Effect) =>
      spawn(function* listenErrors() {
        let isSyncError = false;
        const resetSyncError = () => {
          isSyncError = false;
        };
        let httpError = false;
        while (true) {
          httpError = false;
          isSyncError = true;

          try {
            setTimeout(resetSyncError);

            yield call(function* execSaga() {
              yield saga;
            });

            console.error(
              'Unexpected root saga termination. ' +
                'The root sagas are supposed to be sagas that live during the wole app lifetime!',
              saga,
            );
          } catch (error: any) {
            httpError = typeof error.httpError !== 'undefined';
            if (!httpError && isSyncError) {
              throw new Error(`${saga.name} was terminated because it threw an exception on startup.`);
            }
            yield call(handleError, error);
          }

          if (!httpError) {
            // Para evitar que fallas infinatas bloqueen al browser
            console.error(saga.name, ' will be restarted after 1 second');
          }
        }
      }),
    ),
  );
}

export function* handleError(error: any) {
  if (error?.response?.status === 401) {
    removeItem('token');
    yield put(actions.logout());
  }
}
