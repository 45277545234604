import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ExercisesStoreType } from './types';
import { Exercise } from 'src/services/exercises/types';

const initialState: ExercisesStoreType = {
  exercises: [],
};

const sliceName = 'exercises';

const exercisesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getExercisesRequest: (state: ExercisesStoreType) => {},
    getExercisesSuccess: (state: ExercisesStoreType, action: PayloadAction<Exercise[]>) => {
      state.exercises = action.payload;
    },
    getExercisesError: (state: ExercisesStoreType) => {
      state.exercises = [];
    },
  },
});

export const actions = exercisesSlice.actions;

export const exercisesStore = (state: RootState) => state.exercises;

export default exercisesSlice.reducer;
