export const ROLE_TYPES = ['AD', 'CO'] as const;
export type RoleType = (typeof ROLE_TYPES)[number];
export enum RoleEnum {
  COACH = 1,
  ADMIN = 2,
}

export type AuthStoreType = {
  user: UserType | null;
  message: string;
};

export type UserType = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  role: RoleEnum;
  gymId: number | null;
};

export type JWTPayloadType = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  role: RoleType;
  gym_id: number | null;
};
