// import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { ThemeContext } from './ThemeContext';
import { CustomThemeProps, ThemeVariantsType } from './types';

const ThemeProvider = ({ children }: CustomThemeProps) => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [currentTheme, setCurrentTheme] = useState<ThemeVariantsType>('dark');

  const changeTheme = (theme: ThemeVariantsType) => {
    setCurrentTheme(theme);
    localStorage.setItem('theme', theme);
  };

  // useEffect(() => {
  //   const localTheme = localStorage.getItem('theme');
  //   if (localTheme) setCurrentTheme(localTheme as ThemeVariantsType);
  //   else changeTheme(prefersDarkMode ? 'dark' : 'light');
  // }, [prefersDarkMode]);

  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
        changeTheme,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
