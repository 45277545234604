import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserPassword } from 'src/services/users/types';
import { RootState } from '../store';
import { UsersStoreType } from './types';

const initialState: UsersStoreType = {
  team: [],
  currentUser: null,
  newUserRow: false,
  deleteUserAlert: false,
};

const sliceName = 'users';

const usersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getTeamRequest: (state: UsersStoreType) => {},
    getTeamSuccess: (state: UsersStoreType, action: PayloadAction<User[]>) => {
      state.team = action.payload;
    },
    getTeamError: (state: UsersStoreType) => {
      state.team = [];
    },
    createUserRequest: (state: UsersStoreType, action: PayloadAction<User>) => {},
    createUserSuccess: (state: UsersStoreType, action: PayloadAction<User>) => {
      state.newUserRow = false;
    },
    createUserError: (state: UsersStoreType) => {},
    updateUserRequest: (state: UsersStoreType, action: PayloadAction<User>) => {},
    updateUserSuccess: (state: UsersStoreType, action: PayloadAction<User>) => {
      state.currentUser = null;
    },
    updateUserError: (state: UsersStoreType) => {},
    deleteUserRequest: (state: UsersStoreType, action: PayloadAction<number>) => {},
    deleteUserSuccess: (state: UsersStoreType) => {
      state.currentUser = null;
      state.deleteUserAlert = false;
    },
    deleteUserError: (state: UsersStoreType) => {},
    changePasswordRequest: (state: UsersStoreType, action: PayloadAction<UserPassword>) => {},
    changePasswordSuccess: (state: UsersStoreType) => {},
    changePasswordError: (state: UsersStoreType) => {},
    toggleNewUserRow: (state: UsersStoreType, action: PayloadAction<boolean>) => {
      state.newUserRow = action.payload;
    },
    toggleDeleteUserAlert: (state: UsersStoreType, action: PayloadAction<boolean>) => {
      state.deleteUserAlert = action.payload;
    },
    setCurrentUser: (state: UsersStoreType, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload;
    },
  },
});

export const actions = usersSlice.actions;

export const usersStore = (state: RootState) => state.users;

export default usersSlice.reducer;
