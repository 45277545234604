import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { WorkoutsStoreType } from './types';
import {
  DeleteSuperserieRequest,
  ReorderSuperserieRequest,
  UpdateSuperserieRequest,
  UpdateWorkoutRequest,
  Workout,
  WorkoutDetail,
  WorkoutExercise,
  WorkoutSuperSerie,
} from 'src/services/workouts/types';
import { Exercise } from 'src/services/exercises/types';

const initialState: WorkoutsStoreType = {
  workouts: [],
  currentWorkout: null,
  deleteWorkoutAlert: false,
  workoutsFilter: {
    filtered: [],
    search: '',
  },
  workoutToBeDeleted: null,
  createdWorkoutId: null,
  superserieCreator: {
    isOpen: false,
    name: '',
    searchInput: '',
    exercises: [],
    exercisesList: [],
    currentExerciseId: null,
  },
  deleteSuperserieAlert: false,
  superserieToBeDeleted: null,
};

const sliceName = 'workouts';

const workoutsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getWorkoutsRequest: (state: WorkoutsStoreType) => {},
    getWorkoutsSuccess: (state: WorkoutsStoreType, action: PayloadAction<Workout[]>) => {
      state.workouts = action.payload;
      state.workoutsFilter.search = '';
      state.workoutsFilter.filtered = action.payload;
    },
    getWorkoutsError: (state: WorkoutsStoreType) => {
      state.workouts = [];
      state.workoutsFilter.search = '';
      state.workoutsFilter.filtered = [];
    },
    getWorkoutRequest: (state: WorkoutsStoreType, action: PayloadAction<string>) => {},
    getWorkoutSuccess: (state: WorkoutsStoreType, action: PayloadAction<WorkoutDetail>) => {
      state.createdWorkoutId = null;
      state.currentWorkout = action.payload;
    },
    getWorkoutError: (state: WorkoutsStoreType) => {
      state.currentWorkout = null;
    },
    createWorkoutRequest: (state: WorkoutsStoreType, action: PayloadAction<UpdateWorkoutRequest>) => {},
    createWorkoutSuccess: (state: WorkoutsStoreType, action: PayloadAction<Workout>) => {
      state.createdWorkoutId = action.payload.uuid;
    },
    createWorkoutError: (state: WorkoutsStoreType) => {
      state.createdWorkoutId = null;
    },
    updateWorkoutRequest: (state: WorkoutsStoreType, action: PayloadAction<UpdateWorkoutRequest>) => {},
    updateWorkoutSuccess: (state: WorkoutsStoreType) => {},
    updateWorkoutError: (state: WorkoutsStoreType) => {},
    deleteWorkoutRequest: (state: WorkoutsStoreType, action: PayloadAction<string>) => {},
    deleteWorkoutSuccess: (state: WorkoutsStoreType) => {
      state.deleteWorkoutAlert = false;
    },
    deleteWorkoutError: (state: WorkoutsStoreType) => {},
    createSuperserieRequest: (state: WorkoutsStoreType, action: PayloadAction<UpdateSuperserieRequest>) => {},
    createSuperserieSuccess: (state: WorkoutsStoreType) => {
      state.superserieCreator.isOpen = false;
    },
    createSuperserieError: (state: WorkoutsStoreType) => {},
    updateSuperserieRequest: (state: WorkoutsStoreType, action: PayloadAction<UpdateSuperserieRequest>) => {},
    updateSuperserieSuccess: (state: WorkoutsStoreType) => {
      state.superserieCreator.isOpen = false;
    },
    updateSuperserieError: (state: WorkoutsStoreType) => {},
    deleteSuperserieRequest: (state: WorkoutsStoreType, action: PayloadAction<DeleteSuperserieRequest>) => {},
    deleteSuperserieSuccess: (state: WorkoutsStoreType) => {
      state.deleteSuperserieAlert = false;
    },
    deleteSuperserieError: (state: WorkoutsStoreType) => {},
    reorderSuperserie: (state: WorkoutsStoreType, action: PayloadAction<ReorderSuperserieRequest>) => {},
    reorderSuperserieSuccess: (state: WorkoutsStoreType) => {},
    reorderSuperserieError: (state: WorkoutsStoreType) => {},
    getExercisesRequest: (state: WorkoutsStoreType) => {},
    getExercisesSuccess: (state: WorkoutsStoreType, action: PayloadAction<Exercise[]>) => {
      state.superserieCreator.exercisesList = action.payload;
    },
    getExercisesError: (state: WorkoutsStoreType) => {
      state.superserieCreator.exercisesList = [];
    },
    toggleDeleteWorkoutAlert: (state: WorkoutsStoreType, action: PayloadAction<boolean>) => {
      state.deleteWorkoutAlert = action.payload;
    },
    toggleDeleteSuperserieAlert: (state: WorkoutsStoreType, action: PayloadAction<boolean>) => {
      state.deleteSuperserieAlert = action.payload;
    },
    setWorkoutToBeDeleted: (state: WorkoutsStoreType, action: PayloadAction<Workout | null>) => {
      state.workoutToBeDeleted = action.payload;
    },
    setSuperserieToBeDeleted: (state: WorkoutsStoreType, action: PayloadAction<WorkoutSuperSerie | null>) => {
      state.superserieToBeDeleted = action.payload;
    },
    setCurrentWorkout: (state: WorkoutsStoreType, action: PayloadAction<WorkoutDetail | null>) => {
      state.currentWorkout = action.payload;
    },
    filterWorkouts: (state: WorkoutsStoreType, action: PayloadAction<string>) => {
      const { payload } = action;
      const text = payload.toLowerCase();
      state.workoutsFilter.search = payload;
      state.workoutsFilter.filtered = state.workouts.filter(w => w.name.toLowerCase().includes(text));
    },
    initSuperserieCreator: (state: WorkoutsStoreType, action: PayloadAction<WorkoutSuperSerie | undefined>) => {
      const { payload } = action;
      state.superserieCreator = {
        ...initialState.superserieCreator,
        ...(payload || {}),
        isOpen: true,
      };
    },
    setSearchInput: (state: WorkoutsStoreType, action: PayloadAction<string>) => {
      state.superserieCreator.searchInput = action.payload;
    },
    setCurrentExerciseId: (state: WorkoutsStoreType, action: PayloadAction<number | null>) => {
      state.superserieCreator.currentExerciseId = action.payload;
    },
    setExercises: (state: WorkoutsStoreType, action: PayloadAction<WorkoutExercise[]>) => {
      state.superserieCreator.exercises = action.payload;
    },
    closeSuperserieCreator: (state: WorkoutsStoreType) => {
      state.superserieCreator = { ...initialState.superserieCreator };
    },
    changeSuperserieName: (state: WorkoutsStoreType, action: PayloadAction<string>) => {
      state.superserieCreator.name = action.payload;
    },
  },
});

export const actions = workoutsSlice.actions;

export const workoutsStore = (state: RootState) => state.workouts;

export default workoutsSlice.reducer;
