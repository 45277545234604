import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'password', {
  title: 'Cambiar contraseña',
  currentPassword: 'Contraseña actual',
  newPassword: 'Contraseña nueva',
  newPassword2: 'Repetir contraseña nueva',
  placeholder: 'Ingresá la contraseña',
  change: 'Cambiar',
  cancel: 'Cancelar',
  passwordMinLength: 'La contraseña debe contener al menos 8 caracteres',
  passwordMustMatch: 'Las contraseñas deben coincidir',
});

export default i18n;
