import Button from '@mui/material/Button';
import { ChangePasswordProps } from './types';
import { useTranslation } from 'react-i18next';
import './i18n';
import { Modal, TextField, Typography } from '@mui/material';
import { FormContainer, ButtonsContainer } from './styles';
import { useChangePassword } from './useChangePassword';
import { ChangePasswordFormType } from './schema';

const ChangePassword = ({ open, onClose }: ChangePasswordProps) => {
  const { t } = useTranslation('password');
  const { form } = useChangePassword();

  const hasError = (field: keyof ChangePasswordFormType) => !!form.errors[field] && form.submitCount > 0;

  const renderField = (field: keyof ChangePasswordFormType) => {
    return (
      <TextField
        label={t(field)}
        variant="outlined"
        placeholder={t('placeholder') || ''}
        name={field}
        onChange={form.handleChange}
        error={hasError(field)}
        helperText={hasError(field) ? t(`${form.errors[field]}`) : ''}
      />
    );
  };

  return (
    <Modal open={open} sx={{ zIndex: 1302 }}>
      <FormContainer onSubmit={form.handleSubmit}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          {t('title')}
        </Typography>
        {renderField('currentPassword')}
        {renderField('newPassword')}
        {renderField('newPassword2')}
        <ButtonsContainer>
          <Button type="button" variant="text" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type="submit" variant="contained" disabled={!form.isValid}>
            {t('change')}
          </Button>
        </ButtonsContainer>
      </FormContainer>
    </Modal>
  );
};
export default ChangePassword;
