import { useDispatch, useSelector } from 'react-redux';
import { GlobalLoader } from 'src/components';
import { actions } from 'src/reducers/auth/auth';
import { globalStore } from 'src/reducers/global/global';
import { getValue } from 'src/utils/localStorage';
import { TokenControllerProps } from './types';

const TokenController = ({ children }: TokenControllerProps) => {
  const { loading } = useSelector(globalStore);
  const dispatch = useDispatch();

  const token = getValue('token');
  if (token) {
    dispatch(actions.setUserInformation(token));
  } else {
    dispatch(actions.logout());
  }

  return (
    <>
      {children}
      <GlobalLoader loading={loading} />
    </>
  );
};

export default TokenController;
