const ROUTES = {
  INDEX: '/',
  LOGIN: '/login',
  ADMIN: '/admin',
  TEAM: '/admin/team',
  CLIENTS: '/admin/clients',
  CLIENT: '/admin/clients/:id/',
  CARDIOS: '/admin/cardios',
  EXERCISES: '/admin/exercises',
  WORKOUTS: '/admin/workouts',
  WORKOUT: '/admin/workouts/:id/',
};

export default ROUTES;
