import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'usersSaga', {
  createSuccessfully: 'El usuario fue creado exitosamente',
  updateSuccessfully: 'El usuario fue editado exitosamente',
  deleteSuccessfully: 'El usuario fue eliminado exitosamente',
  createError: 'Ocurrió un error al crear el usuario. Revise los parámetros y vuelva a intentarlo',
  updateError: 'Ocurrió un error al editar el usuario. Revise los parámetros y vuelva a intentarlo',
  deleteError: 'Ocurrió un error al eliminar el usuario. Vuelva a intentarlo en unos minutos',
});

export default i18n;
