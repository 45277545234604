import { AppBar, IconButton, Typography, Menu, MenuItem, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Menu as MenuIcon, AccountCircle, WbSunnyOutlined, DarkModeOutlined } from '@mui/icons-material';
import './i18n';
import { HeaderProps } from './types';
import { useHeader } from './useHeader';
import { useCallback, useContext } from 'react';
import ChangePassword from './components/ChangePassword/ChangePassword';
import SystemDate from './components/SystemDate/SystemDate';
import { ProfileContainer } from './styles';
import { ThemeContext } from 'src/contexts/ThemeContext/ThemeContext';
import { SCREEN_MAX_WIDTH } from 'src/utils/constants';
import { RoleEnum } from 'src/reducers/auth/types';

const Header = ({ onMenuClicked }: HeaderProps) => {
  const { t } = useTranslation('header');
  const { theme, changeTheme } = useContext(ThemeContext);
  const {
    user,
    showChangePasswordModal,
    showSystemDateModal,
    anchorElem,
    handleContextMenu,
    handleClose,
    handlePassword,
    handleLogout,
    handleSystemDate,
  } = useHeader();

  const getUserName = useCallback(() => {
    if (!user) return '';
    const text = `${user.firstName || ''} ${user.lastName || ''}`;
    return text;
  }, [user]);

  return (
    <AppBar position="relative" style={{ zIndex: 99 }}>
      <Toolbar
        style={{
          maxWidth: SCREEN_MAX_WIDTH + 2 * 24,
          margin: 'auto',
          width: '100vw',
        }}>
        <IconButton size="large" color="default" aria-label="menu" onClick={onMenuClicked} sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <div style={{ flex: 1 }}>
          <Typography variant="h6" component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
            IDILICS
          </Typography>
        </div>
        <IconButton
          size="large"
          aria-label="current theme"
          color="default"
          onClick={() => changeTheme(theme === 'light' ? 'dark' : 'light')}>
          {theme === 'light' ? <DarkModeOutlined /> : <WbSunnyOutlined />}
        </IconButton>
        <ProfileContainer onClick={handleContextMenu} aria-controls="menu-appbar" aria-haspopup="true">
          <IconButton size="large" aria-label="account of current user" color="default">
            <AccountCircle />
          </IconButton>
          <Typography variant="subtitle1" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {getUserName()}
          </Typography>
        </ProfileContainer>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElem}
          keepMounted
          open={!!anchorElem}
          onClose={handleClose}
          PaperProps={{ style: { width: 175, right: 0 } }}
          MenuListProps={{ style: { padding: 0 } }}>
          <MenuItem sx={{ padding: '10px 16px' }} onClick={handlePassword(true)}>
            {t('changePassword')}
          </MenuItem>
          {user?.role === RoleEnum.ADMIN && (
            <MenuItem sx={{ padding: '10px 16px' }} onClick={handleSystemDate(true)}>
              {t('systemDate')}
            </MenuItem>
          )}
          <MenuItem sx={{ padding: '10px 16px' }} onClick={handleLogout}>
            {t('logout')}
          </MenuItem>
        </Menu>
        {showChangePasswordModal && <ChangePassword open={showChangePasswordModal} onClose={handlePassword(false)} />}
        {showSystemDateModal && <SystemDate open={showSystemDateModal} onClose={handleSystemDate(false)} />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
