import { get, post, put, remove, patch } from '../api';
import {
  UpdateWorkoutRequest,
  Workout,
  WorkoutDetail,
  ReorderSuperserieRequest,
  UpdateSuperserieRequest,
  DeleteSuperserieRequest,
} from './types';

export const getWorkouts = () => {
  return get<Workout[]>('/api/v1/workouts');
};

export const getWorkout = (uuid: string) => {
  return get<WorkoutDetail>(`/api/v1/workouts/${uuid}`);
};

export const createWorkout = (params: UpdateWorkoutRequest) => {
  const { uuid, ...body } = params;
  return post<Workout>('/api/v1/workouts', body);
};

export const updateWorkout = (params: UpdateWorkoutRequest) => {
  const { uuid, ...body } = params;
  return put(`/api/v1/workouts/${uuid}`, body);
};

export const deleteWorkout = (uuid: string) => {
  return remove(`/api/v1/workouts/${uuid}`);
};

export const reorderSuperserie = (params: ReorderSuperserieRequest) => {
  const { id, ...body } = params;
  return patch<number>(`/api/v1/superseries/${id}`, body);
};

export const createSuperserie = (params: UpdateSuperserieRequest) => {
  const { id, workoutUuid, ...body } = params;
  return post<number>(`/api/v1/workouts/${workoutUuid}/superseries`, body);
};

export const updateSuperserie = (params: UpdateSuperserieRequest) => {
  const { id, workoutUuid, ...body } = params;
  return put(`/api/v1/workouts/${workoutUuid}/superseries/${id}`, body);
};

export const deleteSuperserie = (params: DeleteSuperserieRequest) => {
  return remove(`/api/v1/workouts/${params.workoutUuid}/superseries/${params.id}`);
};
