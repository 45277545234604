import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions, authStore } from 'src/reducers/auth/auth';
import { RoleEnum } from 'src/reducers/auth/types';
import { globalStore, globalActions } from 'src/reducers/global/global';
import ROUTES from 'src/routes/routes';
import { removeItem } from 'src/utils/localStorage';

export const useHeader = () => {
  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);
  const { user } = useSelector(authStore);
  const isSystemDateFetched = useRef(false);
  const { showChangePasswordModal, showSystemDateModal } = useSelector(globalStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElem(event.currentTarget);
  };

  const handleClose = () => setAnchorElem(null);

  const handlePassword = (show: boolean) => () => {
    dispatch(globalActions.toggleChangePasswordModal(show));
    setAnchorElem(null);
  };

  const handleSystemDate = (show: boolean) => () => {
    if (show) dispatch(globalActions.getSystemDateRequest());
    dispatch(globalActions.toggleSystemDateModal(show));
    setAnchorElem(null);
  };

  const handleLogout = () => {
    dispatch(actions.logout());
    removeItem('token');
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (user?.role === RoleEnum.ADMIN && !isSystemDateFetched.current) {
      isSystemDateFetched.current = true;
      dispatch(globalActions.getSystemDateRequest());
    }
  }, [user, dispatch]);

  return {
    user,
    anchorElem,
    showChangePasswordModal,
    showSystemDateModal,
    handleContextMenu,
    handleClose,
    handlePassword,
    handleLogout,
    handleSystemDate,
  };
};
