import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CardiosStoreType } from './types';
import { Cardio } from 'src/services/cardios/types';

const initialState: CardiosStoreType = {
  cardios: [],
};

const sliceName = 'cardios';

const cardiosSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getCardiosRequest: (state: CardiosStoreType) => {},
    getCardiosSuccess: (state: CardiosStoreType, action: PayloadAction<Cardio[]>) => {
      state.cardios = action.payload;
    },
    getCardiosError: (state: CardiosStoreType) => {
      state.cardios = [];
    },
  },
});

export const actions = cardiosSlice.actions;

export const cardiosStore = (state: RootState) => state.cardios;

export default cardiosSlice.reducer;
