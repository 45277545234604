import { RoleEnum } from 'src/reducers/auth/types';
import ROUTES from 'src/routes/routes';
import { MenuItemType } from './types';

const MENU: MenuItemType[] = [
  {
    key: 'team',
    icon: 'person',
    path: ROUTES.TEAM,
    roles: [RoleEnum.ADMIN],
  },
  {
    key: 'clients',
    icon: 'groups',
    path: ROUTES.CLIENTS,
    roles: [RoleEnum.ADMIN, RoleEnum.COACH],
  },
  {
    key: 'cardios',
    icon: 'directions_run',
    path: ROUTES.CARDIOS,
    roles: [RoleEnum.ADMIN, RoleEnum.COACH],
  },
  {
    key: 'exercises',
    icon: 'sports_gymnastics',
    path: ROUTES.EXERCISES,
    roles: [RoleEnum.ADMIN, RoleEnum.COACH],
  },
  {
    key: 'workouts',
    icon: 'fitness_center',
    path: ROUTES.WORKOUTS,
    roles: [RoleEnum.ADMIN, RoleEnum.COACH],
  },
];

export default MENU;
