import styled from 'styled-components';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 0;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  flex-wrap: wrap;
  gap: 24px;
`;
