import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { SearchProps } from './types';

const Search = ({ placeholder, label, value, sx, disabled, variant = "standard", onChange }: SearchProps) => {
  return (
    <TextField
      variant={variant}
      value={value}
      label={label}
      placeholder={placeholder}
      name="search"
      disabled={disabled}
      onChange={e => onChange(e.target.value)}
      sx={sx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value && <ClearIcon onClick={() => onChange('')} sx={{ cursor: 'pointer' }} />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
