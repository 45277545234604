import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';

const PublicLayout = () => {
  const { user } = useSelector(authStore);

  if (user) {
    return <Navigate to={ROUTES.ADMIN} replace />;
  }

  return <Outlet />;
};

export default PublicLayout;
