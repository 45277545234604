import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/workouts/workouts';
import { globalActions } from 'src/reducers/global/global';
import {
  getWorkout as getWorkoutApi,
  getWorkouts as getWorkoutsApi,
  createWorkout as createWorkoutApi,
  updateWorkout as updateWorkoutApi,
  deleteWorkout as deleteWorkoutApi,
  reorderSuperserie as reorderSuperserieApi,
  updateSuperserie as updateSuperserieApi,
  createSuperserie as createSuperserieApi,
  deleteSuperserie as deleteSuperserieApi,
} from 'src/services/workouts/workouts';
import { getExercises as getExercisesApi } from 'src/services/exercises/exercises';
import {
  DeleteSuperserieRequest,
  ReorderSuperserieRequest,
  UpdateSuperserieRequest,
  UpdateWorkoutRequest,
  Workout,
  WorkoutDetail,
} from 'src/services/workouts/types';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';
import { Exercise } from 'src/services/exercises/types';

function* getWorkouts(): Generator<
  CallEffect<AxiosResponse<Workout[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Workout[]>
> {
  try {
    const { status, data } = yield call(getWorkoutsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getWorkoutsSuccess(data));
    } else {
      yield put(actions.getWorkoutsError());
    }
  } catch (e) {
    yield put(actions.getWorkoutsError());
  }
}

function* getWorkout({
  payload,
}: PayloadAction<string>): Generator<
  CallEffect<AxiosResponse<WorkoutDetail>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<WorkoutDetail>
> {
  try {
    const { status, data } = yield call(getWorkoutApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getWorkoutSuccess(data));
    } else {
      yield put(actions.getWorkoutError());
    }
  } catch (e) {
    yield put(actions.getWorkoutError());
  }
}

function* createWorkout({
  payload,
}: PayloadAction<UpdateWorkoutRequest>): Generator<
  CallEffect<AxiosResponse<Workout>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<Workout>
> {
  try {
    const { status, data } = yield call(createWorkoutApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createWorkoutSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('workoutsSaga:createSuccessfully')));
    } else {
      yield put(actions.createWorkoutError());
      yield errorController(i18next.t('workoutsSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createWorkoutError());
    yield errorController(i18next.t('workoutsSaga:createError'), e);
  }
}

function* updateWorkout({
  payload,
}: PayloadAction<UpdateWorkoutRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateWorkoutApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateWorkoutSuccess());
      if (payload.uuid) yield put(actions.getWorkoutRequest(payload.uuid));
      yield put(globalActions.showSuccessSnackbar(i18next.t('workoutsSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateWorkoutError());
      yield errorController(i18next.t('workoutsSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateWorkoutError());
    yield errorController(i18next.t('workoutsSaga:updateError'), e);
  }
}

function* deleteWorkout({
  payload,
}: PayloadAction<string>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteWorkoutApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteWorkoutSuccess());
      yield put(actions.getWorkoutsRequest());
      yield put(globalActions.showSuccessSnackbar(i18next.t('workoutsSaga:deleteSuccessfully')));
    } else {
      yield put(actions.deleteWorkoutError());
      yield errorController(i18next.t('workoutsSaga:deleteError'), data);
    }
  } catch (e) {
    yield put(actions.deleteWorkoutError());
    yield errorController(i18next.t('workoutsSaga:deleteError'), e);
  }
}

function* createSuperserie({
  payload,
}: PayloadAction<UpdateSuperserieRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(createSuperserieApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createSuperserieSuccess());
      yield put(actions.getWorkoutRequest(payload.workoutUuid));
      yield put(globalActions.showSuccessSnackbar(i18next.t('workoutsSaga:createSuperserieSuccessfully')));
    } else {
      yield put(actions.createSuperserieError());
      yield errorController(i18next.t('workoutsSaga:createSuperserieError'), data);
    }
  } catch (e) {
    yield put(actions.createSuperserieError());
    yield errorController(i18next.t('workoutsSaga:createSuperserieError'), e);
  }
}

function* updateSuperserie({
  payload,
}: PayloadAction<UpdateSuperserieRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateSuperserieApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateSuperserieSuccess());
      yield put(actions.getWorkoutRequest(payload.workoutUuid));
      yield put(globalActions.showSuccessSnackbar(i18next.t('workoutsSaga:updateSuperserieSuccessfully')));
    } else {
      yield put(actions.updateSuperserieError());
      yield errorController(i18next.t('workoutsSaga:updateSuperserieError'), data);
    }
  } catch (e) {
    yield put(actions.updateSuperserieError());
    yield errorController(i18next.t('workoutsSaga:updateSuperserieError'), e);
  }
}

function* deleteSuperserie({
  payload,
}: PayloadAction<DeleteSuperserieRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteSuperserieApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteSuperserieSuccess());
      yield put(actions.getWorkoutRequest(payload.workoutUuid));
      yield put(globalActions.showSuccessSnackbar(i18next.t('workoutsSaga:deleteSuperserieSuccessfully')));
    } else {
      yield put(actions.deleteSuperserieError());
      yield errorController(i18next.t('workoutsSaga:deleteSuperserieError'), data);
    }
  } catch (e) {
    yield put(actions.deleteSuperserieError());
    yield errorController(i18next.t('workoutsSaga:deleteSuperserieError'), e);
  }
}

function* reorderSuperserie({
  payload,
}: PayloadAction<ReorderSuperserieRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }>,
  void,
  AxiosResponse
> {
  try {
    const { status } = yield call(reorderSuperserieApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.reorderSuperserieSuccess());
    } else {
      yield put(actions.reorderSuperserieError());
    }
  } catch (e) {
    yield put(actions.reorderSuperserieError());
  }
}

function* getExercises(): Generator<
  CallEffect<AxiosResponse<Exercise[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Exercise[]>
> {
  try {
    const { status, data } = yield call(getExercisesApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getExercisesSuccess(data));
    } else {
      yield put(actions.getExercisesError());
    }
  } catch (e) {
    yield put(actions.getExercisesError());
  }
}

const workoutsSaga: ForkEffect<never>[] = [
  takeLatest(actions.getWorkoutRequest, getWorkout),
  takeLatest(actions.getWorkoutsRequest, getWorkouts),
  takeLatest(actions.reorderSuperserie, reorderSuperserie),
  takeLatest(actions.createWorkoutRequest, createWorkout),
  takeLatest(actions.updateWorkoutRequest, updateWorkout),
  takeLatest(actions.deleteWorkoutRequest, deleteWorkout),
  takeLatest(actions.createSuperserieRequest, createSuperserie),
  takeLatest(actions.updateSuperserieRequest, updateSuperserie),
  takeLatest(actions.deleteSuperserieRequest, deleteSuperserie),
  takeLatest(actions.getExercisesRequest, getExercises),
];

export default workoutsSaga;
