import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/auth/auth';
import { setAuthToken } from 'src/services/api';
import { login as loginApi, changeMyPassword as changeMyPasswordApi } from 'src/services/auth/auth';
import { saveValue } from 'src/utils/localStorage';
import { ChangePasswordRequest, LoginRequestType, LoginResponseType } from 'src/services/auth/types';
import { globalActions } from 'src/reducers/global/global';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';

function* login({
  payload,
}: PayloadAction<LoginRequestType>): Generator<
  CallEffect<AxiosResponse<LoginResponseType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<LoginResponseType>
> {
  try {
    const { status, data } = yield call(loginApi, payload);
    if (status >= 200 && status < 300) {
      saveValue('token', data.access);
      setAuthToken(data.access);
      yield put(actions.loginSuccess(data));
    } else {
      yield put(actions.loginError());
      yield errorController(i18next.t('authSaga:loginError'));
    }
  } catch (e) {
    yield put(actions.loginError());
    yield errorController(i18next.t('authSaga:loginError'));
  }
}

function* changeMyPassword({
  payload,
}: PayloadAction<ChangePasswordRequest>): Generator<
  CallEffect<AxiosResponse<void>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<void>
> {
  try {
    const { status, data } = yield call(changeMyPasswordApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.changeMyPasswordSuccess());
      yield put(globalActions.toggleChangePasswordModal(false));
      yield put(globalActions.showSuccessSnackbar(i18next.t('authSaga:passwordUpdatedSuccessfully')));
    } else {
      yield put(actions.changeMyPasswordError());
      yield errorController(i18next.t('authSaga:passwordUpdateError'), data);
    }
  } catch (e) {
    yield put(actions.changeMyPasswordError());
    yield errorController(i18next.t('authSaga:passwordUpdateError'), e);
  }
}

const authSaga: ForkEffect<never>[] = [
  takeLatest(actions.loginRequest, login),
  takeLatest(actions.changeMyPasswordRequest, changeMyPassword),
];

export default authSaga;
