import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'workoutsSaga', {
  createSuccessfully: 'El workout fue creado exitosamente',
  updateSuccessfully: 'El workout fue editado exitosamente',
  deleteSuccessfully: 'El workout fue eliminado exitosamente',
  createSuperserieSuccessfully: 'La superserie fue creada exitosamente',
  updateSuperserieSuccessfully: 'La superserie fue editada exitosamente',
  deleteSuperserieSuccessfully: 'La superserie fue eliminada exitosamente',
  createError: 'Ocurrió un error al crear el workout. Revise los parámetros y vuelva a intentarlo',
  updateError: 'Ocurrió un error al editar el workout. Revise los parámetros y vuelva a intentarlo',
  deleteError: 'Ocurrió un error al eliminar el workout. Vuelva a intentarlo en unos minutos',
  createSuperserieError: 'Ocurrió un error al crear la superserie. Revise los parámetros y vuelva a intentarlo',
  updateSuperserieError: 'Ocurrió un error al editar la superserie. Revise los parámetros y vuelva a intentarlo',
  deleteSuperserieError: 'Ocurrió un error al eliminar la superserie. Vuelva a intentarlo en unos minutos',
});

export default i18n;
