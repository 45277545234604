import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/cardios/cardios';
import { getCardios as getCardiosApi } from 'src/services/cardios/cardios';
import { Cardio } from 'src/services/cardios/types';

function* getCardios(): Generator<
  CallEffect<AxiosResponse<Cardio[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Cardio[]>
> {
  try {
    const { status, data } = yield call(getCardiosApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getCardiosSuccess(data));
    } else {
      yield put(actions.getCardiosError());
    }
  } catch (e) {
    yield put(actions.getCardiosError());
  }
}

const cardiosSaga: ForkEffect<never>[] = [takeLatest(actions.getCardiosRequest, getCardios)];

export default cardiosSaga;
