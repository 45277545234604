import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga';
import global from './global/global';
import auth from './auth/auth';
import users from './users/users';
import clients from "./clients/clients";
import clientTasks from './clientTasks/clientTasks';
import cardios from './cardios/cardios'
import exercises from './exercises/exercises';
import workouts from './workouts/workouts';

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware];

const reducer = {
  global,
  auth,
  users,
  clients,
  clientTasks,
  cardios,
  exercises,
  workouts
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
