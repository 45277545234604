import { lazy, useMemo, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import TokenController from './layouts/TokenController/TokenController';
import store from './reducers/store';
import { AppContainer } from './styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeContext, ThemeProvider, MenuProvider } from './contexts';
import { darkThemeOptions, lightThemeOptions } from './theme/theme';
import './i18n/i18n';
import PublicLayout from './layouts/PublicLayout/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout/PrivateLayout';
import ROUTES from './routes/routes';
import { LazyLoader, Menu } from './components';
import { RoleEnum } from './reducers/auth/types';
import RoleController from './layouts/RoleController/RoleController';
import { createTheme, CssBaseline, responsiveFontSizes } from '@mui/material';
import Snackbar from './components/Snackbar/Snackbar';
import { esES } from '@mui/x-date-pickers';

const Login = LazyLoader(lazy(() => import('./pages/Login/Login')));

const Team = LazyLoader(lazy(() => import('./pages/Team/Team')));
const Clients = LazyLoader(lazy(() => import('./pages/Clients/Clients')));
const Client = LazyLoader(lazy(() => import('./pages/Client/Client')));
const Cardios = LazyLoader(lazy(() => import('./pages/Cardios/Cardios')));
const Exercises = LazyLoader(lazy(() => import('./pages/Exercises/Exercises')));
const Workouts = LazyLoader(lazy(() => import('./pages/Workouts/Workouts')));
const Workout = LazyLoader(lazy(() => import('./pages/Workout/Workout')));
const NotFound = LazyLoader(lazy(() => import('./pages/NotFound/NotFound')));

const renderPage = (element: JSX.Element, role: RoleEnum) => {
  return (
    <RoleController requiredRole={role} redirect>
      {element}
    </RoleController>
  );
};

const App = () => {
  const { theme: customTheme } = useContext(ThemeContext);
  const theme = useMemo(() => {
    const theme = createTheme(customTheme === 'dark' ? darkThemeOptions : lightThemeOptions, esES);
    return responsiveFontSizes(theme);
  }, [customTheme]);

  return (
    <Provider store={store}>
      <Router>
        <MenuProvider>
          <StyledThemeProvider theme={theme}>
            <AppContainer>
              <CssBaseline />
              <TokenController>
                <Routes>
                  <Route element={<PrivateLayout />}>
                    <Route path={ROUTES.TEAM} element={renderPage(<Team />, RoleEnum.ADMIN)} />
                    <Route path={ROUTES.CLIENTS} element={renderPage(<Clients />, RoleEnum.COACH)} />
                    <Route path={ROUTES.CLIENT} element={renderPage(<Client />, RoleEnum.COACH)} />
                    <Route path={ROUTES.CARDIOS} element={renderPage(<Cardios />, RoleEnum.COACH)} />
                    <Route path={ROUTES.EXERCISES} element={renderPage(<Exercises />, RoleEnum.COACH)} />
                    <Route path={ROUTES.WORKOUTS} element={renderPage(<Workouts />, RoleEnum.COACH)} />
                    <Route path={ROUTES.WORKOUT} element={renderPage(<Workout />, RoleEnum.COACH)} />
                    <Route path={ROUTES.ADMIN} element={<Clients />} />
                  </Route>
                  <Route element={<PublicLayout />}>
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                    <Route path={ROUTES.INDEX} element={<Login />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </TokenController>
              <Snackbar />
            </AppContainer>
            <Menu />
          </StyledThemeProvider>
        </MenuProvider>
      </Router>
    </Provider>
  );
};

const IdilicsAdmin = () => {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
};

export default IdilicsAdmin;
