import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  width: 180px;
  height: 180px;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 160px;
    height: 160px;
  }
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
